/* Estilo global para o container */
.login-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100vh;
    padding: 0 2rem;
    position: relative;
    flex-direction: row; /* Garantir layout lado a lado em telas grandes */
}

/* Imagem do lado esquerdo */
.login-image {
    width: 50%; /* A imagem ocupa 50% da tela em telas grandes */
    height: 100vh;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0; /* Garantir que a imagem fique à esquerda */
    z-index: -1; /* A imagem ficará atrás do formulário em telas grandes */
}

.login-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* A imagem preenche o espaço sem deformar */
}

/* Formulário */
.form-container {
    position: relative;
    z-index: 1; /* Garante que o formulário fique acima da imagem */
    width: 100%;
    max-width: 500px; /* Aumentado o tamanho máximo do formulário */
    padding: 3rem; /* Aumentando o padding para mais espaço */
    background: rgba(255, 255, 255, 0.8) !important; 
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-left: 15px; /* Espaço à esquerda do formulário */
}

/* Cabeçalho */
h1 {
    text-align: center;
    font-size: 3rem; /* Aumentando o tamanho da fonte */
    color: #333;
    margin-bottom: 1.5rem;
}

h6 {
    text-align: center;
    font-size: 1rem;
    margin-bottom: 2rem;
    color: #555;
}

/* Formulário */
.form-group {
    margin-bottom: 2rem; /* Aumentando o espaçamento entre os campos */
}

label {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.5rem; /* Aumentando o tamanho da fonte */
}

input {
    width: 100%;
    padding: 1rem; /* Aumentando o padding dos campos */
    margin-top: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1.5rem; /* Aumentando o tamanho da fonte */
}

.password-container {
    position: relative;
}

.password-container input {
    width: 100%;
}

.password-container .toggle-password {
    width: 10px;
    position: absolute;
    right: 10px; /* Ajuste a distância do lado direito */
    top: 50%;
    transform: translateY(-50%); /* Alinha verticalmente no centro */
    background: transparent;
    border: none;
    color: black; /* Cor preta para o ícone */
    font-size: 20px; /* Tamanho do ícone */
    cursor: pointer;
    z-index: 1; /* Garante que o ícone não sobreponha o input */
}

button {
    width: 100%;
    padding: 1.2rem; /* Aumentando o padding do botão */
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.6rem; /* Aumentando o tamanho da fonte do botão */
}

.footer {
    width: 100%;
    padding: 10px;
}

.footer-links {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.footer-links p {
    margin: 0;
}

.home-link {
    text-align: left;
}

.forgotpass-link {
    text-align: right;
}

.forgotpass-link a {
    color: #007bff;
    text-decoration: none;
}

.forgotpass-link a:hover {
    text-decoration: underline;
}

.btn-secondary {
    background-color: #f1f1f1;
    color: #333;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1rem; /* Aumentando o tamanho da fonte */
}

/* Media Queries para telas menores */
@media (max-width: 768px) {
    .login-container {
        flex-direction: column; /* O layout se torna em coluna */
        align-items: center;
        padding: 2rem 0;
    }

    .login-image {
        position: absolute; /* Garantir que a imagem fique atrás */
        width: 100%;
        height: 100vh; /* A imagem ocupa toda a altura da tela */
        z-index: -1; /* A imagem vai ficar ao fundo */
        top: 0;
        left: 0;
    }

    .form-container {
        background:  rgba(0, 123, 255, 0.8); /* Fundo semitransparente */
        margin-top: 2rem; /* Distância para que o formulário não colida com a imagem */
        padding: 2rem;
        box-shadow: none;
        background: transparent; /* Formulário com fundo transparente */
        text-align: center;
        max-width: 500px; /* Ajuste do formulário para telas menores */
        z-index: 1; /* Garantir que o formulário fique acima da imagem */
    }

    /* Ajuste da posição das labels para ficarem à direita */
    .form-group {
        text-align: right; /* Alinha as labels à direita */
    }

    label {
        display: block;
        margin-bottom: 0.5rem;
        font-size: 1.5rem; /* Aumentando o tamanho da fonte */
        text-align: left; /* Alinha o texto da label à direita */
    }

    /* Opcional: Ajuste dos inputs se necessário */
    input {
        width: 100%;
        padding: 1rem; /* Aumentando o padding dos campos */
        margin-top: 0.5rem;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1.5rem; /* Aumentando o tamanho da fonte */
    }
}
